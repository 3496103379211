<script src="./index.js"></script>
<style scoped>
 .q-drawer__content * {
   white-space: normal !important;
 }
 .offline-div {
   border-radius: 2rem;
   border: 2px solid var(--q-2);
   background-color: rgba(0, 0, 0, 0.7);
   opacity: 60%;
   /* backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px); */
 }
 .offline-msg {
   border-radius: 1rem;
   font-size: 1.5rem;
   border: 1px dashed rgb(255, 255, 255);
   color: rgb(255, 255, 255);
   background-color: rgba(0, 0, 0, 0.7);
   font-weight: bold;
   /* backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px); */
 }
</style>
<template>

  <!-- System notifications -->
  <systemNotification
  />

  <!-- Dialog about limit quota or access by tariff-->
  <q-dialog v-model="noaccesstariffShow" class="z-max">
    <q-card>
      <q-card-section>
        <noAccessByTariff
          class="q-pt-xl"
          :on-route-billing="hideNoAccessTariff"
        />
      </q-card-section>
      <q-card-actions align="between">
        <q-btn
          flat
          style="border-radius: 0.5rem"
          :style="$q.screen.gt.sm ? 'font-size: 1rem;' : 'font-size: 0.75rem;'"
          no-caps
          :label="$t('-raw-general-cancel')"
          color="8"
          class="bg-0"
          @click="hideNoAccessTariff()"
        />
        <q-btn
          flat
          style="border-radius: 0.5rem"
          :style="$q.screen.gt.sm ? 'font-size: 1rem;' : 'font-size: 0.75rem;'"
          no-caps
          :label="$t('-raw-billing-general-upgrade-plan')"
          color="0"
          class="bg-v"
          @click="
            $router.push({ path: `/settings/billing` }), hideNoAccessTariff()
          "
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <!-- Dialog confirmation operation -->
  <q-dialog
    v-model="confirmation.show"
    persistent
    class="z-max"
    @keyup.esc="hideConfirmation(false)"
    @keyup.enter="hideConfirmation(true)"
    @before-show="confirmationKey = null"
  >
    <q-card style="border-radius: 1rem; border: 2px solid var(--q-3);" class="no-shadow bg-1">
      <q-card-section class="row items-center">
        <div class="row full-width">
          <div v-if="confirmation.icon" class="column col-2">
            <q-avatar
            :icon="confirmation.icon"
            class="bg-0 q-mr-sm q-mb-sm"
            :size="$q.screen.gt.sm ? '' : 'md'"
            :class="confirmation.method === 'warning' ? 'text-w' : confirmation.method === 'success'?'text-p':'text-n'"
            />
          </div>
        
          <!-- $t(confirmation.message) -->
          <!-- col-10 -->
          <div class="column" :class="{ 'col-12': !confirmation.icon, 'col-10': confirmation.icon }">
            <span
            class="q-mx-md q-pt-sm"
            :style="
              $q.screen.gt.sm ? 'font-size: 1.25rem;' : 'font-size: 0.9rem;'
            "
            >{{ confirmation.message }}</span
          >
          </div>
        </div>

        <q-input
          v-if="confirmation.key"
          v-model="confirmationKey"
          borderless
          type="text"
          autofocus
          color="v"
          class="full-width bg-2 q-px-md"
          style="border-radius: 1rem"
        />
        <div
          class="q-pt-md"
          v-if="
            confirmation.messageDetails &&
            confirmation.messageDetails.length > 0
          "
        >
          <div
            v-for="item in confirmation.messageDetails"
            :key="item || 'detail'"
          >
            <span
              class="q-mx-md"
              v-if="typeof item === 'string'"
              style="font-size: 1.25rem"
              >{{ item }}</span
            >
            <ul
              v-if="Object.prototype.toString.call(item) === '[object Array]'"
            >
              <li v-for="item_ in item" :key="item_ || 'confirm_li'">
                {{ item_ }}
              </li>
            </ul>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          style="border-radius: 0.5rem"
          :style="$q.screen.gt.sm ? 'font-size: 1rem;' : 'font-size: 0.75rem;'"
          no-caps
          :label="$t('-raw-general-cancel')"
          color="e"
          class="bg-3"
          @click="hideConfirmation(false)"
          v-if="confirmation.method !== 'success'"
        />
        <q-btn
          flat
          style="border-radius: 0.5rem"
          :class="confirmation.method === 'success'? 'bg-p': 'bg-n'"
          :style="$q.screen.gt.sm ? 'font-size: 1rem;' : 'font-size: 0.75rem;'"
          :label="confirmation.buttonOk"
          class="text-0 q-px-lg"
          @click="hideConfirmation(true)"
          :disable="confirmation.key && confirmation.key !== confirmationKey"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <!-- DEBUG MODE -->
  <div
    v-if="debug"
    class="column q-gutter-sm fixed z-max"
    style="top: 1rem; right: 1rem; font-size: 1.25rem"
  >
    <div class="q-py-sm q-px-md bg-black text-white round-both">
      screen: {{ $q.screen.name }}
    </div>
    <div class="q-py-sm q-px-md bg-black text-white round-both">more debug</div>
    <div class="q-py-sm q-px-md bg-black text-white round-both">
      to be added
    </div>
    <div class="q-py-sm q-px-md bg-black text-white round-both">soon... ;)</div>
    <!-- <div class="q-py-sm q-px-md bg-black text-white round-both">stream: {{ $store.state.audio.stream }}</div> -->
  </div>

  <q-layout
    view="hHh LpR lFr"
    class="bg-0"
    :class="debug ? 'debug' : ''"
    v-touch-swipe.right.left="
    $route.matched[1].components['right'] ? handleSwipe : {}
    "
  >
    <div
      style="position: absolute; top: 0px; z-index: 100000 !important"
      :style="{ left: `calc(${window.innerWidth / 2}px - 4.0321rem)` }"
      class="q-px-md offline-msg"
      v-if="$streamer.isConnect.value === false && $route.path !== '/'"
    >
      {{ $t("-raw-streamer-offline-please-wait") }}
    </div>
    <!-- :style="{ background: user ? 'linear-gradient(-45deg, var(--q-t), var(--q-f)) !important' : 'var(--q-0)' }" -->


    <!-- MAIN -->
    <q-page-container class="row wrap">
      <q-page
        class="row col q-ma-none q-py-md1-off"
        id="main_page_container"
      >
        <router-view
          :rightmini="rightmini"
          v-slot="{ Component }"
          :style="{
                  'height': `calc(${window.innerHeight}px - ${$route.matched[1].components.bottom || $route.matched[1].components.bottomright || $route.matched[1].components.bottomright ? '10rem': '6rem'}) !important`,
                }"
          class="col scrollie"
        >
          <!-- try next if above doesn't work: max-height: -webkit-fill-available; -->
          <!-- $q.screen is also an option -->
          <div
            v-if="!user && loading"
            class="column justify-center items-center bg-0"
            style="width: 100%; height: 100%"
          >
            <!-- Loading (but not Voccent.svg) loop -->
            <!-- <q-img
                 v-if="!user && loading"
                 v-show="!user && loading"
                 fit="contain"
                 class="q-mx-auto q-my-auto"
                 src="Voc-Loop.svg"
                 style="height: 60%; width: 60%"
                 spinner-color="v"
                 spinner-size="82px"
                 /> -->
          </div>
          <component v-else :is="Component" />
        </router-view>
      </q-page>
    </q-page-container>

    <!-- TOP -->
    <q-header
      v-model="top"
      class="bg-transparent row no-wrap text-5 q-pa-md"
      style="
        font-weight: 600;
        font-size: 1.65rem;
        z-index: 69 !important;
        height: 5rem !important;
      "
      :style="$q.screen.gt.sm ? 'font-size: 1.65rem' : 'font-size: 1rem'"
    >
      <!-- burgar burger menu -->
      <q-btn
        v-if="false && user"
        flat
        color="v"
        icon="menu"
        style="
          height: 3rem;
          width: 3rem;
        "
        class="z-max q-mr-xs round-1 bg-1"
        @click="left = !left"
      />

      <title-bar key="titlebar" />
      <!-- TODO: remove in /challenge and /channel routes.js and delete -->
      <div style="width: 1rem" />
      <router-view name="top" />

      <q-space />

      <div class="q-mx-auto q-mb-md">
        <inbox v-if="user" />
      </div>
      <avatar v-if="user" shirt=v to=/settings class="q-mb-md z-max" />

      <div v-if="$q.screen.gt.xs && !user" style="width: 3rem; height: 3rem" />
    </q-header>

    <!-- RIGHT -->
    <q-drawer
      persistent
      v-if="user && !decodeURI(this.$route.path).startsWith('/story-pass')"
      behavior="desktop"
      style="overflow: visible !important; z-index: 420 !important"
      class="column no-wrap text-5"
      :class="$q.screen.gt.xs ? '' : 'bg-0'"
      v-model="right"
      side="right"
      :mini="rightmini"
      :mini-width="80"
      :width="
      $q.screen.xs
      ? $q.screen.width
      : $q.screen.sm
      ? $q.screen.width
      : $q.screen.md
      ? $q.screen.width / 3
      : $q.screen.width / 4
      "
    >
      <!-- GenAI Stories -->
      <q-btn
        v-if="
          user && $route.path !== '/create' && $route.name !== '-raw-route-edit'
        "
        flat
        color="v"
        size="md"
        icon="auto_mode"
        class="q-mx-auto round-1 bg-0"
        style="width: 3rem; height: 3rem;"
        @click="$router.push({ path: `/genai` })"
      />

      <!-- mixer -->
      <q-btn
        v-if="
          user && $route.path !== '/create' && $route.name !== '-raw-route-edit'
        "
        flat
        color="v"
        size="md"
        icon="blender"
        class="q-mx-auto round-1 bg-0"
        style="width: 3rem; height: 3rem;"
        @click="$router.push({ path: `/mixer` })"
      />

      <div
        v-if="
          user &&
          $route.path !== '/create' &&
          $route.name !== '-raw-route-edit' &&
          (scopes['get-api-v1-asset-object-:type'] || isSystem)
        "
      >
        <q-btn rounded flat color="v">
          <q-avatar size="3.1207rem">
            <q-icon name="admin_panel_settings" />
          </q-avatar>

          <q-menu>
            <q-list style="min-width: 100px">
              <q-item
                clickable
                v-close-popup
                v-if="$store.getters.userscope['get-api-v1-asset-object-:type']"
                @click="$router.push({ path: `/anomalies` })"
                class="row full-height items-center justify-center"
              >
                <q-icon name="flag" class="q-pr-sm" />
                <q-item-section>Anomalies</q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                v-if="isSystem"
                @click="$router.push({ path: `/library` })"
                class="row full-height items-center justify-center"
              >
                <q-icon name="collections_bookmark" class="q-pr-sm" />
                <q-item-section>Library</q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                v-if="isSystem"
                @click="$router.push({ path: `/organizations` })"
                class="row full-height items-center justify-center"
              >
                <q-icon name="business" class="q-pr-sm" />
                <q-item-section>Organizations</q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                v-if="isSystem"
                @click="$router.push({ path: `/reports` })"
                class="row full-height items-center justify-center"
              >
                <q-icon name="summarize" class="q-pr-sm" />
                <q-item-section>Reports</q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                v-if="isSystem"
                @click="$router.push({ path: `/streamotion-dashboard` })"
                class="row full-height items-center justify-center"
              >
                <q-icon name="insights" class="q-pr-sm" />
                <q-item-section>Streamotion</q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                v-if="isSystem"
                @click="$router.push({ path: `/analytics` })"
                class="row full-height items-center justify-center"
              >
                <q-icon name="analytics" class="q-pr-sm" />
                <q-item-section>Analytics</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
      <!-- authoring -->
      <!-- icon="history_edu"-->

      <q-btn
        v-if="
        user && $route.path !== '/create' && $route.name !== '-raw-route-edit'
        "
        flat
        size="md"
        color="e"
        icon="history_edu"
        class="q-mx-auto q-mb-md round-1 bg-0"
        :class="$route.name == 'route-authoring'?'btn-menu-selected':''"
        style="width: 3rem; height: 3rem;"
        @click="$router.push('/authoring/analytics')"
      />

      <q-space />


      <router-view
        name="right"
        :rightmini="rightmini"
        @showmewhole="rightHandler"
      />

      <div class="column full-width items-center" style="position: relative;">

        <div
          v-if="!$route.matched[1].components.right && user"
          class="flex flex-center"
          :style="
            $route.matched[1].components.action ? '' : 'margin-bottom: 1rem'
          "
        >
          <strong class="commit" style="transform: rotate(180deg)">
            <span class="q-pt-md q-pb-sm">{{
              $h.getItem("VOC_VERSION_WUI_GIT_COMMIT")
            }}</span>
            <span class="commit-sub">/latest WUI commit</span>
          </strong>
        </div>

        <Intercom
          v-if="
          user && $route.path !== '/create' && $route.name !== '-raw-route-edit'
          "
          style="z-index: 2; border-radius: 0.75rem"
          class="q-mx-md q-mb-md bg-v text-0"
          direction="up"
        />

        <!-- no-mic indicator -->
        <div v-if="!stream" class="bg-n" style="position: absolute; bottom: 3rem; right: 1rem; height: 1rem; width: 1rem; border-radius: 0.75rem; background: var(--q-n); z-index: 3; pointer-events: none;"></div>

        <q-fab
          v-if="
            user &&
            $route.path !== '/create' &&
            $route.name !== '-raw-route-edit'
          "
          flat
          depressed
          color="8"
          direction="up"
          class="q-mb-md bg-0"
          style="z-index: 2; width: 3rem; height: 3rem; border-radius: 0.75rem;"
          active-icon="settings"
          icon="settings"
          padding="xs"
          v-model="settingstoggle"
        >
          <div
            class="bg-0 column items-center no-wrap"
            style="gap: 0.75rem; border-radius: 2rem; padding: 0.5rem 0.5rem 0 0.5rem;"
          >
            <div v-if="isSystem">
              <q-btn
                flat
                round
                style="height: 3rem; width: 3rem"
                icon="o_bug_report"
                class="bg-1"
                :color="debug ? 'n' : '5'"
                @click="debug = !debug"
              />
            </div>
            <!-- <loc />-->
            <mic />
            <input-selector direction="up" :parentstate="settingstoggle" />
            <theminator direction="up" :parentstate="settingstoggle" />
            <!-- true = menu on the left; false = menu on the top -->
            <localizator :direction="true" />
          </div>
        </q-fab>
      </div>

      <router-view name="action" />

    </q-drawer>

    <!-- BOTTOM -->
    <q-footer
      v-if="$route.matched[1].components.bottomright || $route.matched[1].components.bottomleft || $route.matched[1].components.bottom"
      class="bg-transparent row no-wrap q-py-md text-5"
      style="
        font-weight: 1000;
        font-family: 'Roboto';
        font-size: 1.75rem;
        z-index: 69 !important;
        height: var(--heightfooter) !important;
      "
    >
      <div
        class="row no-wrap"
        :class="{
          'justify-start': component === 'bottomleft',
          'justify-center': component === 'bottom',
          'justify-end': component === 'bottomright',
          'gt-sm': component === 'bottom',
          col: $route.matched[1].components[component],
        }"
        v-for="component in ['bottomleft', 'bottom', 'bottomright']"
        :key="component"
      >
        <router-view
          :name="component"
          v-if="$streamer.isConnect.value === true"
        />
      </div>
    </q-footer>
    <!--Cookie policy dialog-->
    <q-dialog v-model="dialogCookie" seamless position="bottom" class="z-max">
      <q-card
        class="q-pa-sm bg-2 text-7"
        style="
          max-width: 20rem;
          max-height: 10rem;
          font-size: 0.75rem;
          border: 3px dashed var(--q-t);
        "
      >
        <div class="row text-left">
          <div class="col-12">
            <q-btn
              no-caps
              :label="$t('-raw-cookie-accept')"
              icon-right="done_all"
              class="text-weight-bold text-e bg-0"
              @click="CookieOk"
            />
          </div>
        </div>
        <div class="row content-stretch" style="overflow: auto">
          <div class="col-12 content-stretch">
            {{ $t("-raw-cookie-text") }}
          </div>
        </div>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<!--DON'T use SCOPE here!-->
<style>
:root {
  --heightfooter: 5rem;
}
.q-fab .q-btn {
  border-radius: 0.75rem !important;
}
.btn-menu-selected {
  border: 1px var(--q-v) dashed
}
</style>
